<template>
  <div class="content">
    <div class="searchWrapper">
      <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
      <!--（表单）选项区域-->
      <el-form ref="form" :model="formInline" :inline="true">
        <!--第一行-->

        <div class="col_box">
          <div class="col_left">
            <el-form-item :label="$t('searchModule.Channel_type')">
              <el-select v-model="formInline.channelType" placeholder="请选择">
                <el-option label="全部" value=""> </el-option>
                <el-option
                  v-for="item in channelTypeList"
                  :key="item.channelType"
                  :label="item.channelTypeDesc"
                  :value="item.channelType"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Flow_type')">
              <el-select v-model="formInline.billType" placeholder="请选择">
                <el-option label="全部" value=""> </el-option>
                <el-option
                  v-for="item in billTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Belonging_operator')" prop="operationId">
              <el-select v-model.trim="formInline.operationId" filterable size="15">
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="value.operationName"
                  :value="value.operationId"
                  :key="value.operationId"
                  v-for="value in tenantList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Transaction_amount')">
              <el-input
                v-model="formInline.money"
                :onkeyup="(formInline.money = formInline.money.replace(/[^\d]/g, ''))"
                placeholder="交易金额"
                style="width: 160px"
                maxlength="7"
              ></el-input> </el-form-item
            ><el-form-item :label="$t('searchModule.Channel_serial_number')">
              <el-input
                v-model="formInline.tradeNo"
                placeholder="渠道流水号"
                style="width: 240px"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item :label="$t('searchModule.Native_channel_serial_number')">
            <el-input
              v-model="formInline.tradeNo"
              placeholder="原生渠道流水号"
              style="width: 240px"
            ></el-input>
          </el-form-item> -->
            <el-form-item :label="$t('searchModule.Platform_serial_number')">
              <el-input
                v-model="formInline.sysTradeNo"
                placeholder="平台流水号"
                style="width: 240px"
              ></el-input> </el-form-item
            ><el-form-item label="业务类型" label-width="">
              <el-select v-model="formInline.businessOrderType" placeholder="请选择">
                <el-option label="全部" value=""></el-option>
                <el-option
                  v-for="item in businessList"
                  :key="item.code"
                  :label="item.desc"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Trading_Time')">
              <timeRangePick
                @timeChange="timeChange"
                ref="timeRangePicker"
                :defalutDate="defalutDate"
              />
            </el-form-item>
          </div>
          <div class="col_right">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                pageNum = 1;
                searParkRecordList();
              "
              :loading="loading"
              >{{ $t('button.search') }}</el-button
            >
            <el-button icon="el-icon-delete" @click="resaat">{{ $t('button.reset') }}</el-button>
          </div>
        </div>
        <div class="col_box_boder"></div>
        <div class="col_box h44">
          <div class="col_left"></div>
          <div class="col_right mbd4">
            <el-button icon="el-icon-upload2" @click="exportList()">{{ $t('button.export') }}</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <!--列表-->
    <div class="tableWrapper bgFFF paddingB10">
      <!-- <h2 class="title">
        <div class="title_icon" ></div>
        <span class="titlecontent">处理记录列表</span>
      </h2> -->
      <!-- <el-table  :data="tableData" style="width: 100%!important" :header-row-class-name="headerTitle" :row-class-name="rowItem"> -->
      <el-table :data="tableData" style="width: 100% !important">
        <el-table-column
          align="center"
          :label="item.label"
          :min-width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
        >
          <template slot="header">
            {{ item.label }}
            <span v-if="item.prop == 'outTradeNo'">
              <el-tooltip popper-class="tooltip" placement="top">
                <i class="el-icon-question"></i>
                <div slot="content" class="tooltip-content">
                  <div>无渠道流水时，取平台方流水号</div>
                </div>
              </el-tooltip>
            </span>
          </template>
          <template slot-scope="scope">
            <span
              v-if="item.prop == 'parkOrderAppealId'"
              v-clipboard:copy="scope.row.parkOrderAppealId"
              v-clipboard:success="onCopy"
              class="orderNumberStyle"
            >
              <p>{{ scope.row.parkOrderAppealId }}</p>
            </span>
            <span
              v-else-if="item.prop == 'businessId'"
              v-clipboard:copy="scope.row.businessId"
              v-clipboard:success="onCopy"
              class="orderNumberStyle"
            >
              <p>{{ scope.row.businessId }}</p>
            </span>
            <span v-else>
              <p>
                {{ item.formatter ? item.formatter(scope.row) : scope.row[item.prop] }}
              </p>
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              type="text"
              style="padding: 0;"
              @click="seeDetails(scope.row)"
              :disabled="scope.row.orderType == 1"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import { setIndex, dateFormat, exportExcelNew } from "@/common/js/public.js";
export default {
  name: "payRecordTotal",
  data() {
    return {
      channelTypeList: [
        { label: "待审核", value: "0" },
        { label: "已完成", value: "1" },
        { label: "已驳回", value: "2" },
      ],
      billTypeList: [
        { label: "支付", value: "1" },
        { label: "退款", value: "2" },
      ],
      tenantList: [],
      businessList: [],
      defalutDate: [],
      pageNum: 1,
      total: 0,
      pageSize: 15,
      loading: false,
      tableData: [],
      formInline: {
        // parkOrderAppealId: "",
        // businessType: "",
        // businessId: "",
        // status: "",
        startTime: "",
        endTime: "",

        channelType: "",
        billType: "",
        operationId: "",
        money: "",
        tradeNo: "",
        sysTradeNo: "",
        businessOrderType: "",
      },
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "transTimeStr",
          label: "交易时间",
          width: "180",
        },
        {
          prop: "operationName",
          label: this.$t("list.Belonging_operator"),
          width: "180",
        },
        {
          prop: "channelTypeName",
          label: "渠道类型",
          width: "120",
        },
        {
          prop: "billTypeName",
          label: "流水类型",
          width: "120",
        },
        {
          prop: "money",
          label: "交易金额(元)",
          width: "180",
          formatter: (row, column) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "tradeNo",
          label: "平台流水号",
          width: "140",
        },
        {
          prop: "outTradeNo",
          label: "渠道流水号",
          width: "180",
        },
        // {
        //   prop: "tradeNo",
        //   label: "原生渠道流水号",
        //   width: "180",
        // },
        {
          prop: "businessOrderTypeName",
          label: "业务类型",
          width: "180",
        },
      ],
    };
  },
  watch: {},
  methods: {
    resaat() {
      this.formInline.channelType = "";
      this.formInline.billType = "";
      this.formInline.operationId = "";
      this.formInline.money = "";
      this.formInline.tradeNo = "";
      this.formInline.sysTradeNo = "";
      this.formInline.businessOrderType = "";
      this.$refs.timeRangePicker.resetTime();
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/tenantPayment/listAllOperationIds").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 业务订单类型
    getOrderAppeal() {
      let opt = {
        method: "get",
        url: "/acb/2.0/orderAppeal/allBusinessOrderTypeEnum",
        data: {},
        success: (res) => {
          this.businessList = res.value;
        },
      };
      this.$request(opt);
    },
    // 渠道类型
    getTransactionFlow() {
      let opt = {
        method: "get",
        url: "/acb/2.0/payOrder/payChannelTypeDictList",
        data: {},
        success: (res) => {
          this.channelTypeList = res.value;
        },
      };
      this.$request(opt);
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searParkRecordList();
    },
    // 设置地址
    setRouterUrl(typeNum, data) {
      if (data.billType == 1) {
        switch (typeNum) {
          case 0:
            return "parkingPaymentOrder";
          case 3:
            return "finParkingCardManage";
          case 5:
            return "finParkingCardManage";
          case 6:
            return "finParkingCardManage";
          case 7:
            return "parkingPaymentOrder";
          default:
            return "";
        }
      }
    },
    // 查看跳转
    seeDetails(data) {
      // ACS3.3 如果exception是0，按照原来的跳
      // 如果是1，跳转到重复支付列表页面。 如果是2 跳到异常支付列表页面
      if (data.exceptionType == 1) {
        this.$router.push({
          name: "payRepeat",
          params: {
            orderNo: data.exceptionOrderNo ? data.exceptionOrderNo : "1",
          },
        });
        return;
      }
      console.log("data.exception", data);
      if (data.exceptionType == 2) {
        this.$router.push({
          name: "abnormalPayment",
          params: {
            orderNo: data.exceptionOrderNo ? data.exceptionOrderNo : "1",
          },
        });
        return;
      }
      if (data.billType == 2) {
        this.$router.push({
          name: "refundManagement",
          params: {
            tradeNo: data.outTradeNo,
          },
        });
        return;
      }
      /**
       * data.orderType
       * 0: {code: 0, desc: "停车订单"}
        1: {code: 1, desc: "充电订单"}
        2: {code: 2, desc: "充值订单"}
        3: {code: 3, desc: "停车卡订单"}
        4: {code: 4, desc: "充电预约订单"}
        5: {code: 5, desc: "停车预约订单"}
        6: {code: 6, desc: "错峰停车"}
        7: {code: 7, desc: "预付费订单"}
       */
      let paramsStr = {
        ...data,
        startDate: this.formInline.startTime,
        endDate: this.formInline.endTime,
      };
      // if (data.billType == 1) {
      //    if (data.orderType == 7) {
      //     rouStr = "parkingPaymentOrder";
      //     paramsStr = {
      //       tradeNo: data.outTradeNo,
      //     };
      //   }
      //    if (data.orderType == 1) {
      //     rouStr = "parkingPaymentOrder";
      //     paramsStr = {
      //       tradeNo: data.tradeNo,
      //     };
      //   }
      //   if (data.orderType == 0) {
      //     rouStr = "parkingPaymentOrder";
      //     paramsStr = {
      //       tradeNo: data.outTradeNo,
      //     };
      //   }
      //   if (data.orderType == 3) {
      //     rouStr = "finParkingCardManage";
      //     paramsStr = {
      //       tradeNo: data.outTradeNo,
      //     };
      //   }
      //   if (data.orderType == 5) {
      //     rouStr = "finBookOrder";
      //     paramsStr = {
      //       payOutTradeNo: data.outTradeNo,
      //     };
      //   }
      //   if (data.orderType == 6) {
      //     rouStr = "finStaggeredOrder";
      //     paramsStr = {
      //       tradeNo: data.outTradeNo,
      //     };
      //   }
      // }
      // if (data.billType == 2) {
      //   rouStr = "refundManagement";
      //   paramsStr = {
      //     tradeNo: data.outTradeNo,
      //   };
      // }
      this.$router.push({
        name: this.setRouterUrl(data.orderType, data),
        params: paramsStr,
      });
    },
    // 导出
    exportList() {
      exportExcelNew("/acb/2.0/transactionFlow/export", {
        channelType: this.formInline.channelType,
        billType: this.formInline.billType,
        operationId: this.formInline.operationId,
        money: this.formInline.money ? this.formInline.money * 100 : "",
        tradeNo: this.formInline.tradeNo,
        businessOrderType: this.formInline.businessOrderType,
        startDate: this.formInline.startTime,
        endDate: this.formInline.endTime,
        sysTradeNo: this.formInline.sysTradeNo,
      });
    },
    // 搜索
    searParkRecordList() {
      this.searParkRecordListFun();
      // let flag = this.showLog();
      // if (flag) {
      //   this.flag = false;
      // }
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },
    searParkRecordListFun() {
      let opt = {
        method: "get",
        url: "/acb/2.0/transactionFlow/list",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          channelType: this.formInline.channelType,
          billType: this.formInline.billType,
          operationId: this.formInline.operationId,
          money: this.formInline.money ? this.formInline.money * 100 : "",
          tradeNo: this.formInline.tradeNo,
          businessOrderType: this.formInline.businessOrderType,
          startDate: this.formInline.startTime,
          endDate: this.formInline.endTime,
          sysTradeNo: this.formInline.sysTradeNo,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    get7Day(data) {
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * data);
      return dateFormat(start, "yyyy-MM-dd") + " 00:00:00";
    },
  },

  created() {
    this.defalutDate = [this.get7Day(7), this.dataTimeRest() + " 23:59:59"];
    this.formInline.handleTimeStart = this.defalutDate[0];
    this.formInline.handleTimeEnd = this.defalutDate[1];
    this.formInline.appealTimeStart = this.defalutDate[0];
    this.formInline.appealTimeEnd = this.defalutDate[1];
    console.log(this.formInline);
  },
  mounted() {
    this.getTransactionFlow();
    this.getTenantList();
    this.getOrderAppeal();
    this.searParkRecordList();
  },
  components: {
    timeRangePick,
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.processingStyle {
  // width: 99%;
  // background: #FFFFFF;
  // border-radius: 8px;
  // padding 16px
  // box-sizing: border-box
}



.pagerWrapper {
  text-align: right;
  margin-top: 28px;
  font-size: 12px;
}

.el-form {
  padding-top: 23px;
}

.optionAreaOne {
  display: flex;
  padding-left: 8px;
}

.optionAreaThree {
  display: flex;
  padding-left: 8px;
}

.optionAreaThree2 {
  display: flex;
  justify-content: right;
}

.title {
  margin-bottom: 15px;
}

.titlecontent {
  font-size: 14px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #2F2F51;
}

.warning {
  color: red;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.titleh1 {
  margin: 10px 10px;
  font-size: 15px;
}

.buts {
  height: 40px;
  margin-right: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.but {
  height: 40px;
  margin-right: 15px;
  margin-bottom: 15px;
  text-align: center;
  border: 1px solid #527BFF;
  color: #527BFF;
}

.orderNumberStyle {
  color: #0f6eff;
}

.el-table>>> .headerTitleS th {
  background: #F3F7FF;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
}

.el-table>>> .rowItem {
  background: #F9FBFF;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.08);
  height: 50px;
  padding: 0px;
}

.el-table>>> .rowItem2 {
  background: #FFFFFF;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.08);
  height: 50px;
  padding: 0px;
}
</style>
